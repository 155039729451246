<template>
    <div class="homepage-wrapper">
        <!--        <div id="preloader"></div>-->
        <Header/>
        <!-- header end -->
        <div class="slide-area slide-area-3 fix heading-area" data-stellar-background-ratio="0.6" style="background: #222e3d !important;">
            <div class="display-table">
                <div class="display-table-cell">
                    <div class="container">
                        <div class="row">
                            <div class="text-center">
                              <br>
                              <br>
                                <h1 style="color:#ffffff">Support</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Start About Area -->
        <div class="about-area bg-color fix area-padding">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="section-headline text-center">
                            <h3>Hi, how can we help you?</h3>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="support-all">
                        <div class="row">
                            <!-- Start services -->
                            <div class="col-md-12 col-sm-12 col-lg-6 col-xs-12">
                                <div class="row">
                                    <!--TODO Need to change to Transcrypt address-->
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8257988732385!2d103.84579521532903!3d1.27802699906785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1933635ab7e9%3A0x26f49e439c265128!2sTransCrypt!5e0!3m2!1sen!2ssg!4v1616645285450!5m2!1sen!2ssg"
                                            width="100%" height="450" style="border:0;" allowfullscreen=""
                                            loading="lazy"></iframe>
                                </div>

                            </div>
                            <!-- Start services -->
                            <div class="col-md-12 col-sm-12 col-lg-6 col-xs-12">
                                <div class="row">
                                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                                        <label for="name" class="control-label"> Name</label>
                                        <div class="input-group">
                                            <input type="text" id="name" name="name"
                                                   class="form-control" v-model="name">
                                        </div>

                                    </div>
                                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                                        <label for="email" class="control-label">Email</label>
                                        <div class="input-group">
                                            <input type="email" id="email" name="email"
                                                   class="form-control" v-model="email"
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xs-12 col-md-12 col-lg-12 mb-3">
                                        <label for="message" class="control-label"> Message</label>
                                        <div class="input-group">
                                        <textarea id="message" name="message"
                                                  class="form-control" v-model="message">

                                        </textarea>
                                        </div>
                                    </div>

                                </div>

                                <div class="row pl-2">
                                    <button class="btn btn-success" @click="submitEnquiries()">Submit</button>
                                </div>

                                <div class="row pl-2 mt-2">
                                    <p class="text-primary" v-if="contactSupportSuccessful">Thank you for your enquiries
                                        ! We will reply you within 1 to 2 business days.</p>
                                    <p class="text-error" v-else-if="contactSupportUnsuccessful">Submit enquiry error.
                                        Please ensure to enter a valid email or contact us at
                                        support@transcryptglobal.com</p>
                                    <p class="text-error" v-else-if="validationContactSupportError">Please fill in all
                                        the above fields.</p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- End About Area -->

        <!-- Start brand Banner area -->
<!--        <div class="brand-area area-padding fix" data-stellar-background-ratio="0.6">-->
<!--            <div class="container">-->
<!--                <div class="row">-->
<!--                    <div class="col-md-12 col-sm-12 col-xs-12">-->
<!--                        <div class="brand-text text-center">-->
<!--                            <h3>Ready to cash out / send money with your cryptocurrency?</h3>-->
<!--                            <br>-->
<!--                            <br>-->
<!--                            <router-link class="hire-btn gtm-get-started" to="/account/register">Get started</router-link>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <!-- End brand Banner area -->


        <!-- Start Footer Area -->
        <Footer/>
        <!-- End Footer Area -->
    </div>
</template>
<script>
    import Footer from "../layouts/HomepageFooter"
    import Header from "../layouts/HomepageHeader"
    import {mapActions} from 'vuex';

    export default {
        name: 'support',
        components: {Footer,Header},
        data() {
            return {
                name: "",
                email: "",
                message: "",

                validationContactSupportError: false,
                contactSupportSuccessful: false,
                contactSupportUnsuccessful: false,

                debugConsole : false,

            }
        },
        metaInfo: {
            title: 'Contact TransCrypt | Send Money Faster with Cryptocurrency',
            meta: [
                {
                    name: 'description',
                    content: 'Money transfer with cryptocurrency wherever and whenever with no limitations. Contact us at support@transcryptglobal.com for more information.'
                }
            ]
        },
        mounted() {
            if(this.debugConsole)  console.debug("Support Page Mounted");
            require('@/assets/js/main.js');

            //check if mean container is loaded
           if(this.debugConsole) console.log("Mean container loaded", window.jQuery('.mean-container'))
            if (window.jQuery('.mean-container').length === 0) {
                window.jQuery('nav#dropdown').meanmenu()
            }


        },
        methods: {
            ...mapActions(["contactSupport"]),
            submitEnquiries() {


                if (!this.name || (!this.email || this.email && this.email.indexOf('@') < 2) || !this.message) {
                    this.validationContactSupportError = true;
                } else {
                    this.validationContactSupportError = false;
                }

                if (this.validationContactSupportError) {
                    return;
                }


                let payload = {};
                payload.name = this.name;
                payload.email = this.email;
                payload.message = this.message;

                this.contactSupport(payload).then(res => {
                    if(this.debugConsole)  console.log("Contact support result in support page", res.data);
                    if (res.data && res.data.status === "OK") {
                        this.contactSupportSuccessful = true;
                        this.contactSupportUnsuccessful = false;
                        this.validationContactSupportError = false

                        if (this.contactSupportSuccessful) {
                            this.name = "";
                            this.email = "";
                            this.message = "";
                        }
                    }
                }).catch(err => {
                    if(this.debugConsole) console.log("Contact support error in support page")
                    this.contactSupportSuccessful = false;
                    this.contactSupportUnsuccessful = true;
                    this.validationContactSupportError = false;
                })
            }
        }
    }

</script>
<style scoped>
    ul > li {
        list-style-type: none;
    }
</style>
